import { IButtonAction, IDynamicForm } from "config";
import { BREAK_ACTIONS, IButtonActionOptions } from "containers/DynamicForm/useButtonAction";
import replaceWyshwareUri from "utils/replaceWyshwareUri";

import { useCheckWyshAllocations } from "./useCheckWyshAllocations";
import { useCreateAcknowledgements } from "./useCreateAcknowledgements";
import { useCreateResponse } from "./useCreateResponse";
import { useDismiss } from "./useDismiss";
import { useDivideEvenly } from "./useDivideEvenly";
import { useExecuteGroupApi } from "./useExecuteGroupApi";
import { useExitFlowWarning } from "./useExitFlowWarning";
import { useFinishFlow } from "./useFinishFlow";
import { useFormUnsavedDiffWarning } from "./useFormUnsavedDiffWarning";
import { useNextForm } from "./useNextForm";
import { usePop } from "./usePop";
import { usePopToRoot } from "./usePopToRoot";
import { useSetFormAction } from "./useSetFormAction";
import { useShowInsuranceApplication } from "./useShowInsuranceApplication";
import { useShowSuccessOverlay } from "./useShowSuccessOverlay";
import { useUpdateBeneficiaryAllocations } from "./useUpdateBeneficiaryAllocations";
import { useValidateForm } from "./useValidateForm";

export const useGetButtonCommand = () => {
  const checkWyshAllocationsCommand = useCheckWyshAllocations();
  const createAcknowledgementsCommand = useCreateAcknowledgements();
  const createResponseCommand = useCreateResponse();
  const dismissCommand = useDismiss();
  const divideEvenly = useDivideEvenly();
  const executeGroupApi = useExecuteGroupApi();
  const exitFlowWarning = useExitFlowWarning();
  const finishFlow = useFinishFlow();
  const formUnsavedDiffWarningCommand = useFormUnsavedDiffWarning();
  const nextFormCommand = useNextForm();
  const popCommand = usePop();
  const popToRootCommand = usePopToRoot();
  const setFormActionCommand = useSetFormAction();
  const showInsuranceApplication = useShowInsuranceApplication();
  const showSuccessOverlayCommand = useShowSuccessOverlay();
  const updateBeneficiaryAllocations = useUpdateBeneficiaryAllocations();
  const validateFormCommand = useValidateForm();

  const getApiButtonCommand = (command: IButtonAction["command"]): TApiButtonCommandFn => {
    const commandDestination = command.destination as ButtonCommandDestinationTypes;

    switch (commandDestination) {
      case "checkWyshAllocations":
        return checkWyshAllocationsCommand;

      case "createAcknowledgements":
        return createAcknowledgementsCommand;

      case "createResponse":
        return createResponseCommand;

      case "dismiss":
        return dismissCommand;

      case "divideEvenly":
        return divideEvenly;

      case "executeGroupApi":
        return executeGroupApi;

      case "exitFlowWarning":
        return exitFlowWarning;

      case "finishFlow":
        return finishFlow;

      case "formUnsavedDiffWarning":
        return formUnsavedDiffWarningCommand;

      case "nextForm":
        return nextFormCommand;

      case "pop":
        return popCommand;

      case "popToRoot":
        return popToRootCommand;

      case "setFormAction":
        return setFormActionCommand;

      case "showInsuranceApplication":
        return showInsuranceApplication;

      case "showSuccessOverlay":
        return showSuccessOverlayCommand;

      case "updateBeneficiaryAllocations":
        return updateBeneficiaryAllocations;

      case "validateForm":
        return validateFormCommand;

      default:
        return () => false;
    }
  };

  const getLinkButtonCommand = (command: IButtonAction["command"]) => {
    return replaceWyshwareUri(command.destination) || "/";
  };

  return {
    getApiButtonCommand,
    getLinkButtonCommand,
  };
};

/* ------------------    TYPES    ------------------ */
type ButtonCommandDestinationTypes = keyof typeof EnumButtonCommandApiDestination;

enum EnumButtonCommandApiDestination {
  checkWyshAllocations,
  createAcknowledgements,
  createResponse,
  dismiss,
  divideEvenly,
  executeGroupApi,
  exitFlowWarning,
  finishFlow,
  formUnsavedDiffWarning,
  nextForm,
  pop,
  popToRoot,
  popToDashboard,
  setFormAction,
  showInsuranceApplication,
  showSuccessOverlay,
  updateBeneficiaryAllocations,
  validateForm,
}

export type TApiButtonCommandFnArgs = IButtonActionOptions;

export type TApiButtonCommandFn = (
  commandArgs: IButtonAction["command"]["arguments"],
  buttonActionArgs: TApiButtonCommandFnArgs
) =>
  | boolean
  | string
  | void
  | Promise<any[] | IDynamicForm | string | void | { formAction: string }>
  | typeof BREAK_ACTIONS;
