import { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { productOfferReviewEvent } from "analytics/productOfferEvents";
import { BillingFrequency, IReinstateablePolicy } from "config/types";
import AboutAD from "containers/AboutAD/AboutAd";
import CaseResultAD from "containers/CaseResultAD/CaseResultAD";
import CaseResultTerm from "containers/CaseResultTerm/CaseResultTerm";
import CaseResultTermReinstatement from "containers/CaseResultTerm/CaseResultTermReinstatement";
import TriviaContainer from "containers/Trivia/Trivia";
import { useJourney, usePolicyReinstatementData, usePolling } from "modules";
import CoverageDeniedRoute from "router/CoverageDeniedRoute/CoverageDeniedRoute";
import CoverageEditorRoute from "router/CoverageEditorRoute/CoverageEditorRoute";
import { RootState, useAppDispatch } from "store";
import { getEligibleForCoverageUnderwriting } from "store/actions/account";
import { eventFired } from "store/actions/analytics";
import { setShowJourneyProgress } from "store/actions/journey";
import { getReinstateablePoliciesPolling } from "store/actions/policy";
import { offeringsReviewPolling } from "store/actions/product-offerings";
import { getProfile } from "store/actions/profile";
import { getWyshAllocationSummary } from "store/actions/wyshes";
import {
  getWyshADOffering,
  getWyshTermLifeOffering,
  selectReinstatementProductOfferingByPolicyId,
  useParamSelector,
} from "store/selectors";
import { getEventAlreadyFired, setEventFiredOnceTracker } from "utils/analytics";
import { logBranchUnderwritingFailureEvent } from "utils/branch";

import { some } from "lodash-es";

interface IProps {
  callback?(type: string, callback?: () => void): (event?: MouseEvent) => void;
}

const QuoteRoute: FC<IProps> = () => {
  const { data, rootJourney } = useJourney();
  const defaultShowAboutAD = data?.showAboutAD || false;
  const isReinstatementJourney = rootJourney?.id === "reinstatementFulfillment";
  const reinstateablePolicyId = rootJourney?.metadata
    ?.reinstateablePolicyId as IReinstateablePolicy["id"];
  const { refetch: getReinstateablePolicies } = usePolicyReinstatementData();
  const [editingType, setEditingType] = useState<"termLifeOffering" | "adOffering" | "">("");
  const [billingFrequency] = useState<BillingFrequency>("monthly");
  const [quoteDenied, setQuoteDenied] = useState(false);
  const [latestCase, setLatestCase] = useState({ result: "", id: "" });
  const [showAboutAD, setShowAboutAD] = useState(defaultShowAboutAD);

  const dispatch = useAppDispatch();

  const termLifeOffering = useSelector(getWyshTermLifeOffering);
  const adOffering = useSelector(getWyshADOffering);
  const reinstatementOffering = useParamSelector(
    selectReinstatementProductOfferingByPolicyId,
    reinstateablePolicyId
  );

  const latestCaseResult = useSelector((state: RootState) => state.insuranceApplication.result);

  const isQuoteDenied = (result: string) => {
    const denied = some(
      ["rejected", "failed", "referred_to_underwriter"],
      status => status === result
    );
    setQuoteDenied(denied);
    return denied;
  };

  const isQuoteReady = useMemo(() => {
    return (
      latestCaseResult !== "pending" &&
      (quoteDenied ||
        !!termLifeOffering?.id ||
        (!!adOffering?.id && adOffering.status !== "expired") ||
        !!reinstatementOffering?.id)
    );
  }, [
    adOffering?.id,
    latestCaseResult,
    quoteDenied,
    termLifeOffering?.id,
    adOffering?.status,
    reinstatementOffering?.id,
  ]);

  const [triviaInProgress, setTriviaInProgress] = useState(!isQuoteReady);

  useEffect(() => {
    dispatch(setShowJourneyProgress(false));
  }, [dispatch]);

  useEffect(() => {
    if (termLifeOffering?.id || quoteDenied) {
      dispatch(setShowJourneyProgress(true));
    }
  }, [dispatch, termLifeOffering?.id, quoteDenied]);

  useEffect(() => {
    if (isReinstatementJourney && quoteDenied) {
      getReinstateablePolicies();
    }

    if (quoteDenied) {
      logBranchUnderwritingFailureEvent(latestCaseResult);
    }
  }, [quoteDenied]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const caseResult = latestCase.result;
    const caseId = latestCase.id;
    const shouldFireEvent =
      caseResult &&
      caseResult !== "pending" &&
      caseId &&
      !getEventAlreadyFired("fulfillment_uw_decisioned", caseId);

    if (shouldFireEvent) {
      dispatch(
        eventFired({
          event: "fulfillment_uw_decisioned",
          experienceLocation: "ONBOARDING",
          attribute: {
            underwritingDecision: caseResult,
            applicationId: caseId,
          },
        })
      );
      setEventFiredOnceTracker("fulfillment_uw_decisioned", caseId);
    }
  }, [dispatch, latestCase.id, latestCase.result, termLifeOffering?.id]);

  const getOfferingsReview = useCallback(async () => {
    const response = await dispatch(offeringsReviewPolling()).unwrap();
    const { caseResult, caseId, productOfferings } = response;

    const termOffering = productOfferings.find(offering => {
      return offering.productSlug === "wysh-term-life";
    });

    const adProductOffer = productOfferings.find(offering => {
      return offering.productSlug === "wysh-accidental-death";
    });

    setLatestCase({ result: caseResult, id: caseId });
    const denied = isQuoteDenied(caseResult);

    return denied || !!termOffering?.id || !!adProductOffer?.id;
  }, [dispatch]);

  const getReinstatementOfferingsReview = useCallback(async () => {
    const { caseResult = "", caseId = "", productsOffering } = await dispatch(
      getReinstateablePoliciesPolling(reinstateablePolicyId)
    ).unwrap();

    setLatestCase({ id: caseId, result: caseResult });

    return isQuoteDenied(caseResult) || !!productsOffering?.id;
  }, [dispatch, reinstateablePolicyId]);

  const onEditorSave = () => {
    setEditingType("");
  };

  const onEditorBack = () => {
    setEditingType("");
  };

  const onClickEdit = (offeringType: "termLifeOffering" | "adOffering") => {
    setEditingType(offeringType);
  };

  const handleOnClickAdCta = () => {
    setShowAboutAD(true);
  };

  const onClickAboutAdBack = () => {
    setShowAboutAD(false);
  };

  const status = usePolling(
    !isReinstatementJourney ? getOfferingsReview : getReinstatementOfferingsReview,
    150
  );

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getWyshAllocationSummary());
  }, [dispatch]);

  useEffect(() => {
    if (status === "done") {
      dispatch(productOfferReviewEvent());
    }

    if (status === "done" || status === "timed-out") {
      dispatch(getEligibleForCoverageUnderwriting());
    }
  }, [dispatch, status]);

  // Display Trivia until it completes
  if (triviaInProgress) {
    return (
      <TriviaContainer
        isFailedQuote={status === "timed-out" || (isQuoteReady && latestCaseResult === "failed")}
        isQuoteReady={isQuoteReady}
        onComplete={() => setTriviaInProgress(false)}
        requestStatus={status}
      />
    );
  }

  if (status === "timed-out") {
    return <CoverageDeniedRoute type="failed" />;
  }

  if (editingType) {
    return (
      <CoverageEditorRoute
        type={editingType}
        billingFrequency={billingFrequency}
        onSave={onEditorSave}
        onBack={onEditorBack}
      />
    );
  }

  if (showAboutAD) {
    return <AboutAD onClickBack={onClickAboutAdBack} onClickEdit={onClickEdit} />;
  }

  if (
    latestCaseResult === "rejected" ||
    latestCaseResult === "failed" ||
    latestCaseResult === "referred_to_underwriter"
  ) {
    return (
      <CoverageDeniedRoute
        isReinstatementJourney={isReinstatementJourney}
        type={latestCaseResult}
      />
    );
  } else if (isReinstatementJourney) {
    return <CaseResultTermReinstatement />;
  } else if (termLifeOffering?.id) {
    return <CaseResultTerm onClickEdit={onClickEdit} />;
  } else {
    return <CaseResultAD onClickEdit={onClickEdit} onClickCta={handleOnClickAdCta} />;
  }
};

export default QuoteRoute;
